import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'wizards/';

class WizardsService {
  createPartners(data) {
    return axios.post(API_URLA + 'createPartners/', data);
  }
  gamesBillsChange(bills, games) {
    return axios.post(API_URLA + 'gamesBillsChange/', {"bills": bills, "games": games});
  }
  gameBillsAdd(bills, game) {
    return axios.post(API_URLA + 'gameBillsAdd/', {"bills": bills, "game": game});
  }
  gameBillsDel(bills, game) {
    return axios.post(API_URLA + 'gameBillsDel/', {"bills": bills, "game": game});
  }
  gamesByBillList(bills, curr) {
    return axios.post(API_URLA + 'gamesByBillList/', {"bills": bills, "curr": curr});
  }
  publicJPData(bills, game_id, grade_code) {
    return axios.post(API_URLA + 'publicJPData/', {"bills": bills, "game_id": game_id, "grade_code": grade_code});
  }
  saveHardlimits(bills, data) {
    return axios.post(API_URLA + 'saveHardlimits/', {"bills": bills, "data": data});
  }
  saveChangeJP(data) {
    return axios.post(API_URLA + 'saveChangeJP/', data);
  }
  eventsFailed(data) {
    return axios.post(API_URLA + 'eventsFailed/', data);
  }
  eventRecalc(data) {
    return axios.post(API_URLA + 'eventRecalc/', data);
  }
  systemLogAnalysis(data) {
    return axios.post(API_URLA + 'systemLogAnalysis/', data);
  }
  systemLogAnalysisUpdate() {
    return axios.post(API_URLA + 'systemLogAnalysisUpdate/');
  }
  systemLogAnalysisDel(data) {
    return axios.post(API_URLA + 'systemLogAnalysisDel/', data);
  }
}

export default new WizardsService();
