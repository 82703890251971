<template>
  <p></p>
  <div v-if="SelBills.length<=0" class="container-fluid">{{ $t('wizards.changegame.changegame.pleaseSelectAt') }}</div>
  <div v-if="SelBills.length>0" class="container-fluid">
    <div class="form-group row">
      <label class="col-md-2 col-form-label">{{ $t('bill.bill.hardlimit') }} $</label>
      <div class="col-md-10">
        <input class="form-control" type="number" min="0" v-model="data.hardlimit">
        <p></p>
        <p v-for="curr_hardlimit of curr_hardlimits" :key="curr_hardlimit">{{curr_hardlimit}}</p>
      </div>
    </div>

    <button v-if="data.hardlimit!=''" @click="apply" class="btn btn-sm btn-success m-1">{{ $t('app.public.apply') }}</button>
  </div>
</template>

<script setup>
import WizardsService from "../../../services/wizards.service";
import CurrencyService from "../../../services/currencie.service";
import {computed, inject, onMounted, ref, watch, watchEffect} from "vue";

const $t = inject('$t')

const selBillsInfo = inject('selBillsInfo')

const data=ref({'hardlimit': ''})

const typew = ref('')
const sel_games = ref([])
const sel_game = ref('')

const set_result = inject('set_result')
const cntSelBill = inject('cntSelBill')
const selOneBill = inject('SelOneBill')
const SelBills = inject('SelBills')
const SelBillsCur = ref([])

const currencies_list = ref([])
const lists = ref([])

function veryfyCur()
{
  let cur_list=[]

  for(let key in selBillsInfo.value) {
    let cur=selBillsInfo.value[key]
    cur_list.push(cur['currency'])
  }

  let cur_unic=Array.from(new Set(cur_list))
  cur_unic.sort()

  SelBillsCur.value=cur_unic
}
watchEffect(veryfyCur)

function curr_hardlimitsFn()
{
  let hardlimits_list=[]

  if(data.value.hardlimit!=undefined && data.value.hardlimit!='') {
    for (let key in currencies_list.value) {
      let cur = currencies_list.value[key]

      if (SelBillsCur.value.includes(cur.iso) && cur.iso!='USD' && cur.iso!='AAA_MULTY') {
        hardlimits_list.push(cur.iso+': '+data.value.hardlimit/cur.usd)
      }
    }
  }

  return hardlimits_list
}
const curr_hardlimits=computed(curr_hardlimitsFn)

function getCurrencies()
{
  CurrencyService.getCurrencies().then(
      (response) => {
        currencies_list.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function apply()
{
  WizardsService.saveHardlimits(SelBills.value, data.value).then(
      (response) => {
        set_result()
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

onMounted(() => {
  getCurrencies()
})
</script>

<style scoped>
</style>